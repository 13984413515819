<web-layout-header
  [menuTogglePosition]="menuPosition"
  [menuToggleEnabled]="menuToggleEnabled"
  (menuToggle)="setMenuState(!menuOpened)"
>
  <ng-content select="[slot=header]"></ng-content>
</web-layout-header>
<dx-drawer
  class="layout-body"
  [position]="menuPosition"
  [closeOnOutsideClick]="closeOnOutsideClick"
  [openedStateMode]="menuMode"
  [revealMode]="menuRevealMode"
  height="100%"
  [shading]="shaderEnabled"
  [opened]="menuOpened"
  (openedChange)="setMenuState($event)"
>
  <ng-content
    select="[slot=side]"
    *dxTemplate="let data of 'panel'"
  ></ng-content>
  <dx-scroll-view [ngClass]="{ 'with-footer': footerEnabled }">
    <ng-content></ng-content> </dx-scroll-view
  ><ng-content *ngIf="footerEnabled" select="[slot=footer]"></ng-content>
</dx-drawer>
