import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { DxTreeViewComponent, DxTreeViewModule } from 'devextreme-angular';
import { DxTreeViewTypes } from 'devextreme-angular/ui/tree-view';
import * as events from 'devextreme/events';
import { TranslateModule } from '@codeandweb/ngx-translate';
import { MenuItem } from '../../interface';

@Component({
  selector: 'web-layout-side-menu',
  standalone: true,
  imports: [CommonModule, RouterModule, DxTreeViewModule, TranslateModule],
  templateUrl: './side-menu.component.html',
  styleUrl: './side-menu.component.scss',
})
export class SideMenuComponent /**implements  AfterViewInit, OnDestroy */ {
  @Input() menuItems: MenuItem[] = [];
  @Input() userMenuItems: MenuItem[] = [];
  @Output() menuItemClicked = new EventEmitter<MenuItem>();

  // @ViewChild(DxTreeViewComponent, { static: true })
  // menu!: DxTreeViewComponent;

  // @Output()
  // selectedItemChanged = new EventEmitter<DxTreeViewTypes.ItemClickEvent>();

  // @Output()
  // openMenu = new EventEmitter<any>();

  // private _selectedItem!: string;
  // @Input()
  // set selectedItem(value: string) {
  //   this._selectedItem = value;
  //   if (!this.menu.instance) {
  //     return;
  //   }

  //   this.menu.instance.selectItem(value);
  // }

  // private _items!: Record<string, unknown>[];
  // get items() {
  //   if (!this._items) {
  //     this._items = this.menuItems.map((item) => {
  //       if (item.path && !/^\//.test(item.path)) {
  //         item.path = `/${item.path}`;
  //       }
  //       return { ...item, expanded: !this._compactMode };
  //     });
  //   }

  //   return this._items;
  // }

  // private _compactMode = false;
  // @Input()
  // get compactMode() {
  //   return this._compactMode;
  // }
  // set compactMode(val) {
  //   this._compactMode = val;

  //   if (!this.menu.instance) {
  //     return;
  //   }

  //   if (val) {
  //     this.menu.instance.collapseAll();
  //   } else {
  //     this.menu.instance.expandItem(this._selectedItem);
  //   }
  // }

  // constructor(private elementRef: ElementRef) {}

  // onItemClick(event: DxTreeViewTypes.ItemClickEvent) {
  //   this.selectedItemChanged.emit(event);
  // }

  // ngAfterViewInit() {
  //   events.on(this.elementRef.nativeElement, 'dxclick', (e: Event) => {
  //     this.openMenu.next(e);
  //   });
  // }

  // ngOnDestroy() {
  //   events.off(this.elementRef.nativeElement, 'dxclick');
  // }
}
