import { Output, Injectable, EventEmitter } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map, ReplaySubject, shareReplay } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ScreenService {
  @Output() changed = new EventEmitter();
  private changedSubject = new ReplaySubject<{
    sizes: Record<string, boolean>;
    quantifier: string;
  }>(1);
  public changed$ = this.changedSubject.asObservable();

  constructor(private breakpointObserver: BreakpointObserver) {
    this.breakpointObserver
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
      ])
      .subscribe(() => {
        this.changed.next(true);
        this.changedSubject.next({
          sizes: this.sizes,
          quantifier: this.qualifier,
        });
      });
  }

  private isLargeScreen() {
    const isLarge = this.breakpointObserver.isMatched(Breakpoints.Large);
    const isXLarge = this.breakpointObserver.isMatched(Breakpoints.XLarge);

    return isLarge || isXLarge;
  }

  public get qualifier(): string {
    return this.breakpointObserver.isMatched(Breakpoints.XSmall)
      ? 'xs'
      : this.breakpointObserver.isMatched(Breakpoints.Small)
      ? 'sm'
      : this.breakpointObserver.isMatched(Breakpoints.Medium)
      ? 'md'
      : 'lg';
  }

  public get sizes(): Record<string, boolean> {
    return {
      'screen-x-small': this.breakpointObserver.isMatched(Breakpoints.XSmall),
      'screen-small': this.breakpointObserver.isMatched(Breakpoints.Small),
      'screen-medium': this.breakpointObserver.isMatched(Breakpoints.Medium),
      'screen-large': this.isLargeScreen(),
    };
  }
}
