import {
  DxButtonModule,
  DxDrawerModule,
  DxScrollViewModule,
} from 'devextreme-angular';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OpenedStateMode, RevealMode } from 'devextreme/ui/drawer';
import { ScreenService } from '../../services';
import { HeaderComponent } from '../header/header.component';

@Component({
  selector: 'web-layout-side-nav-layout',
  standalone: true,
  imports: [
    CommonModule,
    DxDrawerModule,
    DxScrollViewModule,
    DxButtonModule,
    HeaderComponent,
  ],
  templateUrl: './side-nav-layout.component.html',
  styleUrl: './side-nav-layout.component.scss',
})
export class SideNavLayoutComponent implements OnInit {
  @Input() closeOnOutsideClick!: boolean;
  @Input() shaderEnabled!: boolean;
  @Input() menuMode!: OpenedStateMode;
  @Input() menuRevealMode!: RevealMode;
  @Input() menuOpened!: boolean;
  @Input() menuPosition: 'left' | 'right' = 'left';
  @Input() menuToggleEnabled = true;
  @Input() footerEnabled = true;
  @Output() menuOpenedChange = new EventEmitter<boolean>();

  constructor(private screen: ScreenService) {}

  ngOnInit(): void {
    this.screen.changed$.subscribe(({ sizes }) => {
      this.closeOnOutsideClick =
        typeof this.closeOnOutsideClick === 'boolean'
          ? this.closeOnOutsideClick
          : !sizes['screen-large'];
      this.shaderEnabled =
        typeof this.shaderEnabled === 'boolean'
          ? this.shaderEnabled
          : !sizes['screen-large'];
      this.menuOpened =
        typeof this.menuOpened === 'boolean'
          ? this.menuOpened
          : sizes['screen-large'];
      this.menuMode =
        this.menuMode || sizes['screen-large'] ? 'shrink' : 'overlap';
      this.menuRevealMode =
        this.menuRevealMode || sizes['screen-x-small'] ? 'slide' : 'expand';
      // this.minMenuSize = sizes['screen-x-small'] ? 0 : 60;
    });
  }

  setMenuState = (menuState: boolean) => {
    this.menuOpened = menuState;
    this.menuOpenedChange.emit(menuState);
  };
}
