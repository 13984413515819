import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DxButtonModule, DxToolbarModule } from 'devextreme-angular';

@Component({
  selector: 'web-layout-header',
  standalone: true,
  imports: [CommonModule, DxButtonModule, DxToolbarModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent {
  @Input() title!: string;
  @Input() menuToggleEnabled = false;
  @Input() menuTogglePosition: 'left' | 'right' = 'left';
  @Output() menuToggle = new EventEmitter<boolean>();

  toggleMenu = () => {
    this.menuToggle.emit();
  };
}

// user: IUser | null = { email: '' };

// userMenuItems = [
//   {
//     text: 'Profile',
//     icon: 'user',
//     onClick: () => {
//       this.router.navigate(['/profile']);
//     },
//   },
//   {
//     text: 'Logout',
//     icon: 'runner',
//     onClick: () => {
//       this.authService.logOut();
//     },
//   },
// ];

// constructor(private authService: AuthService, private router: Router) {}

// ngOnInit() {
//   this.authService.getUser().then((e) => (this.user = e.data));
// }
