<ng-content select="[slot=top]"></ng-content>
<div class="w3-sidebar w3-bar-block" slot="side">
  <a
    *ngFor="let route of menuItems"
    [routerLink]="route.path"
    class="w3-bar-item w3-button"
    [ngClass]="route.cssClass || ''"
    translate
    (click)="menuItemClicked.emit(route)"
  >
    {{ route.label }}
  </a>
  <hr />
  <a
    *ngFor="let route of userMenuItems"
    [routerLink]="route.path"
    class="w3-bar-item w3-button bottom"
    [ngClass]="route.cssClass || ''"
    translate
    (click)="menuItemClicked.emit(route)"
  >
    {{ route.label }}
  </a>
</div>
<ng-content></ng-content>
<ng-content select="[slot=bottom]"></ng-content>
