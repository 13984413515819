import { Component } from '@angular/core';

@Component({
  selector: 'web-layout-content-container',
  standalone: true,
  imports: [],
  templateUrl: './content-container.component.html',
  styleUrl: './content-container.component.scss',
})
export class ContentContainerComponent {}
